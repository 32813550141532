import Image from "./Image";

export default function captureImage(video: HTMLVideoElement) {
  const canvas = document.createElement("canvas");
  const width = video.videoWidth;
  const height = video.videoHeight;
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext("2d");
  // console.log("video size = (" + video.videoWidth + ", " + video.videoHeight + ")");
  // canvas.style.width = width + "px";
  // canvas.style.height = height + "px";
  // if (width > height) {
  //   // PC
  //   let ratio = height / width;
  //   canvas.style.width = (ratio * 100) + "vh";
  // } else {
  //   // Smartphone
  //   let ratio = width / height;
  //   canvas.style.height = (ratio * 100) + "vw";
  // }
  
  // if (video.videoWidth > video.videoHeight) {
  //   // PC
  //   let ratio = video.videoHeight / video.videoWidth;
  //   console.log("[PC] ratio = " + ratio);
  //   canvas.style.width = "100%";
  //   canvas.style.height = (ratio * 100) + "%";
  //   console.log("height = " + canvas.style.height);
  // } else {
  //   // Smartphone
  //   let ratio = video.videoWidth / video.videoHeight;
  //   console.log("[SP] ratio = " + ratio);
  //   canvas.style.width = (ratio * 100) + "%";
  //   canvas.style.height = "100%";
  //   console.log("height = " + canvas.style.width);
  // }

  // draw the video to the canvas
  context!.drawImage(video, 0, 0, width, height);
  // get the raw image bytes
  const imageData = context!.getImageData(0, 0, width, height);
  // convert to greyscale
  const bytes = new Uint8ClampedArray(width * height);
  for (let y = 0; y < height; y++) {
    const row = y * width;
    for (let x = 0; x < width; x++) {
      //const r = imageData.data[(y * width + x) * 4];
      const g = imageData.data[(row + x) * 4 + 1];
      // const b = imageData.data[(y * width + x) * 4 + 2];
      // https://en.wikipedia.org/wiki/Grayscale#Converting_color_to_grayscale
      // const grey = 0.299 * r + 0.587 * g + 0.114 * b;
      bytes[row + x] = g;
    }
  }
  return new Image(bytes, width, height);
}
